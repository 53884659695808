// Import the functions you need from the SDKs you need
import { initializeApp, getApps } from "firebase/app";
import { getFunctions } from 'firebase/functions';
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAqGGlOaTeR_90IjeT9Jq_rlJr-N_URZZs",
    authDomain: "swan-social-poc.firebaseapp.com",
    projectId: "swan-social-poc",
    storageBucket: "swan-social-poc.appspot.com",
    messagingSenderId: "138671248317",
    appId: "1:138671248317:web:9fc8e5c4dce9abef5978d4"
};

// Initialize Firebase
let firebase = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
const functions = getFunctions(firebase);
const storage = getStorage(firebase);
const db = getFirestore(firebase);

export default firebase;
