import { useEffect, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import { getFunctions, httpsCallable } from "firebase/functions";
import AudioRecorder from '../components/AudioRecorder'
import Waveform from '../components/Waveform'
import { useLocation } from "wouter";

import Landing from '../assets/images/landing1.png'
import Video1 from '../assets/images/video1.png'
import Video2 from '../assets/images/video2.png'
import Video3 from '../assets/images/video3.png'
import Video4 from '../assets/images/video4.png'
import Article from '../assets/images/article.png'
import Hey from '../assets/images/Hey.png';
import Talk from '../assets/images/Talk about anything....png';
import Xoxo from '../assets/images/xoxo.png';

const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
    });
};

function convertAudioObjectToURL(audioObject) {
    // Convert the audio object to a byte array
    const byteArray = new Uint8Array(Object.keys(audioObject).length);
    Object.keys(audioObject).forEach(key => {
        byteArray[parseInt(key, 10)] = audioObject[key];
    });

    // Create a blob from the byte array
    const blob = new Blob([byteArray], { type: 'audio/mp3' });

    // Create a URL for the blob
    return URL.createObjectURL(blob);
}

// Usage in a React component
function AudioPlayer({ audioObject }) {
    const audioURL = convertAudioObjectToURL(audioObject);

    return <audio controls src={audioURL} />;
}


export default function Example() {
    const functions = getFunctions();
    const [location, setLocation] = useLocation();

    const [audioURL, setAudioURL] = useState("");
    const [audioData, setAudioData] = useState(null);
    const [isLoadingAudio, setIsLoadingAudio] = useState(false);

    const onRecorded = (url) => {
        setAudioURL(url);
    };

    useEffect(() => {
        if (audioURL && !audioData) {
            getVoiceDemo();
        }

    }, [audioURL]);

    const getVoiceDemo = async () => {
        console.log('audioURL', audioURL)
        let base64 = await blobToBase64(audioURL);
        base64 = base64.replace('codecs=opus;', '');
        console.log('base64', base64);

        const getDemoVoice = httpsCallable(functions, 'demoVoice');
        setIsLoadingAudio(true);
        getDemoVoice({ voice: base64, text: 'Communicate directly with your audience in a way that feels personal and authentic, no matter the size of your community.' })
            .then((result) => {
                console.log(result);
                setAudioData(convertAudioObjectToURL(result?.data?.audio));
                setIsLoadingAudio(false);
            }).catch(error => {
                console.log(error);
            })
    };

    const reset = () => {
        setAudioURL("");
        setAudioData(null);
        setIsLoadingAudio(false);
    }

    return (
        <div className="h-full overflow-y-scroll overflow-x-hidden md:overflow-hidden">
            <header className="absolute inset-x-0 top-0 z-50 bg-white border-b-8 border-emerald-300">
                <div className="border-b-8 border-sky-400">
                    <div className="border-b-8 border-pink-400">

                        <nav className="flex items-center justify-between p-4 lg:px-6" aria-label="Global">
                            <div className="flex lg:flex-1">
                                <div className="-m-1.5 p-1.5 inline-flex items-center space-x-2">
                                    <div className="text-4xl"></div>
                                    <div className="text-2xl font-mono font-semibold leading-6 text-gray-900">[swan labs]</div>
                                </div>
                            </div>

                            <div className="hidden lg:flex lg:flex-1 lg:justify-end opacity-80">
                                <div onClick={() => { setLocation('/auth') }} href="#" className="text-md font-semibold leading-6 text-black bg-white border-2 border-black px-4 py-2 items-center rounded-full">
                                    Get Started <span aria-hidden="true">&rarr;</span>
                                </div>
                            </div>

                        </nav>
                    </div>
                </div>

            </header>

            <div className="relative isolate pt-20 md:pt-24 h-full md:overflow-hidden">

                <div className="md:h-full mx-auto flex-col md:flex-row md:flex-grow lg:flex lg:items-stretch lg:justify-stretch ">
                    <div className="mx-auto max-w-2xl flex flex-col lg:mx-0 lg:flex-auto md:bg-white h-full ">

                        <div className="relative flex flex-col items-start justify-start bg-white px-6 md:px-8 py-6 md:pt-12 md:pb-6 ">
                            <h1 className="w-full text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                Chat one-to-one with your whole community
                            </h1>
                            <p className="mt-4 md:mt-4 text-lg leading-8 text-gray-600">
                                Communicate directly with your audience in a way that feels personal and authentic, no matter the size of your community.

                            </p>
                            <div className="mt-6 md:mt-4 flex items-center gap-x-6">
                                <a
                                    href="#"
                                    className="rounded-md bg-sky-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Get started
                                </a>
                                <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                                    Learn more <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </div>






                        <div className="">
                            <dl className=" max-w-md space-y-8 text-base leading-7 text-gray-600 lg:max-w-none px-6 md:px-8 pt-6 md:pt-0 md:pb-2 ">

                                <div className="bg-white -border-4 border-gray-200 -shadow p-2 rounded-2xl space-y-6">

                                    <div key="1" className="relative pl-9 space-x-1 pr-9">
                                        <dt className="inline text-xl font-semibold text-gray-900">
                                            <CloudArrowUpIcon className="absolute left-1 top-1 h-5 w-5 text-pink-400" aria-hidden="true" />
                                            1. Learn your voice
                                        </dt>
                                        <dd className="">Upload a 10 second voice message to get started!</dd>
                                        {/*
                                        <div className="w-full rounded-full bg-gray-200 h-12 mt-2 flex flex-row items-center justify-center group hover:cursor-pointer hover:shadow-inner">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 opacity-50 group-hover:opacity-80 ">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z" />
                                                </svg>
                                        </div>
                                        */}
                                        {!audioURL && <AudioRecorder onRecorded={onRecorded} />}
                                        {isLoadingAudio && <div className="text-gray-500/50 pr-2">Loading...</div>}
                                        {audioData && <div className="flex flex-row items-center w-full space-x-2"> <Waveform audio={audioData} /><div onClick={reset} className="text-md font-semibold cursor-pointer hover:text-sky-400">Reset</div></div>}
                                    </div>
                                    <div onClick={getVoiceDemo} key="2" className="relative pl-9 space-x-1 flex flex-col pr-9">
                                        <dt className="inline text-xl font-semibold text-gray-900">
                                            <LockClosedIcon className="absolute left-1 top-1 h-5 w-5 text-sky-400" aria-hidden="true" />
                                            2. Learn who you are
                                        </dt>
                                        <dd className="">Connecting your socials is the fastest way to start</dd>
                                        <div className="w-full rounded-xl bg-gray-200 mt-2 flex flex-row items-center justify-between px-3 py-3 overflow-x-scroll no-scrollbar space-x-2 md:space-x-0 shadow-inner md:shadow-none">
                                            <div className="flex flex-row space-x-2 items-center bg-white shadow border border-gray-300 rounded-lg px-2 py-2 hover:cursor-pointer hover:shadow-inner">
                                                <div className="flex flex-row items-center justify-center w-5">
                                                    <i className="fa-brands fa-youtube" style={{ color: '#ff0505' }}></i>
                                                </div>
                                                <div className="text-sm font-semibold text-gray-600">YouTube</div>
                                            </div>
                                            <div className="flex flex-row space-x-2 items-center bg-white shadow border border-gray-300 rounded-lg px-2 py-2 hover:cursor-pointer hover:shadow-inner">
                                                <div className="flex flex-row items-center justify-center w-5">
                                                    <i className="fa-brands fa-tiktok"></i>
                                                </div>
                                                <div className="text-sm font-semibold text-gray-600">TikTok</div>
                                            </div>
                                            <div className="flex flex-row space-x-2 items-center bg-white shadow border border-gray-300 rounded-lg px-2 py-2 hover:cursor-pointer hover:shadow-inner">
                                                <div className="flex flex-row items-center justify-center w-5">
                                                    <i className="fa-brands fa-twitch" style={{ color: '#7d52ff' }}></i>
                                                </div>
                                                <div className="text-sm font-semibold text-gray-600">Twitch</div>
                                            </div>
                                            <div className="flex flex-row space-x-2 items-center bg-white shadow border border-gray-300 rounded-lg px-2 py-2 hover:cursor-pointer hover:shadow-inner">
                                                <div className="flex flex-row items-center justify-center w-5">
                                                    <i className="fa-light fa-newspaper"></i>
                                                </div>
                                                <div className="text-sm font-semibold text-gray-600">Articles</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div key="3" className="relative pl-9 space-x-1 flex flex-col pr-9 pb-2">
                                        <dt className="inline text-xl font-semibold text-gray-900">
                                            <ServerIcon className="absolute left-1 top-1 h-5 w-5 text-emerald-400" aria-hidden="true" />
                                            3. Choose how you want to engage
                                        </dt>
                                        <dd className="">Fully control how you engage, including what to to talk about </dd>

                                        <div className="w-full rounded-full mt-2 flex flex-row items-center justify-between">
                                            <div className="flex flex-row space-x-2 px-2 py-2 rounded-full bg-gray-100 items-center text-gray-600 hover:cursor-pointer hover:shadow hover:bg-gray-200">
                                                <i class="fa-regular fa-message-medical"></i>
                                                <div className="text-sm font-semibold text-gray-600">Advice</div>
                                            </div>
                                            <div className="flex flex-row space-x-2 px-2 py-2 rounded-full bg-gray-100 items-center text-gray-600 hover:cursor-pointer hover:shadow hover:bg-gray-200">
                                                <i class="fa-regular fa-face-awesome"></i>
                                                <div className="text-sm font-semibold text-gray-600">Playful</div>
                                            </div>

                                            <div className="flex flex-row space-x-2 px-2 py-2 rounded-full bg-gray-100 items-center text-gray-600 hover:cursor-pointer hover:shadow hover:bg-gray-200">
                                                <i class="fa-regular fa-crystal-ball"></i>
                                                <div className="text-sm font-semibold text-gray-600">Magical</div>
                                            </div>

                                            <div className="hidden md:flex flex-row space-x-2 px-2 py-2 rounded-full bg-gray-100 items-center text-gray-600 hover:cursor-pointer hover:shadow hover:bg-gray-200">
                                                <i class="fa-regular fa-comments-question-check"></i>
                                                <div className="text-sm font-semibold text-gray-600">Curious</div>
                                            </div>

                                            <div className="hidden md:flex flex-row space-x-2 px-3 py-2 rounded-full bg-gray-100 items-center text-gray-600 hover:cursor-pointer hover:shadow hover:bg-gray-200">
                                                <i class="fa-regular fa-message-heart"></i>
                                                <div className="text-sm font-semibold ">Flirting</div>
                                            </div>
                                        </div>
                                        <div className="w-full rounded-full mt-2 flex flex-row items-center space-x-2 group hover:cursor-pointer hover:shadow-inner">
                                            <div className="flex flex-row shrink-0 grow-1 space-x-2 px-3 py-2 rounded-full bg-gray-100 items-center text-gray-600 hover:cursor-pointer hover:shadow hover:bg-gray-200">
                                                <i class="fa-regular fa-mailbox-flag-up"></i>
                                                <div className="text-sm font-semibold text-gray-600">Pen-pal</div>
                                            </div>
                                            <div className="hidden md:flex flex-row shrink-0 grow-1 space-x-2 px-3 py-2 rounded-full bg-gray-100 items-center text-gray-600 hover:cursor-pointer hover:shadow hover:bg-gray-200">
                                                <i class="fa-regular fa-face-meh"></i>
                                                <div className="text-sm font-semibold text-gray-600">Quizzical</div>
                                            </div>

                                            <div className="hidden md:flex flex-row shrink-0 grow-1 space-x-2 px-4 py-2 rounded-full bg-gray-100 items-center text-gray-600 hover:cursor-pointer hover:shadow hover:bg-gray-200">
                                                <i class="fa-sharp fa-regular fa-bat"></i><div className="text-sm font-semibold text-gray-600">Goth</div>
                                            </div>

                                            <div className="flex flex-row shrink-0 grow-1 space-x-2 px-3 py-2 rounded-full bg-gray-100 items-center text-gray-600 hover:cursor-pointer hover:shadow hover:bg-gray-200">
                                                <i class="fa-regular fa-shield-check"></i>
                                                <div className="text-sm font-semibold text-gray-600">Hype-Person</div>
                                            </div>


                                        </div>

                                    </div>
                                </div>


                            </dl>
                        </div>

                    </div>

                    <div className="z-10 relative flex md:hidden flex-col w-full h-full md:pt-0">
                        <div className="absolute w-full h-full grid grid-cols-6 grid-rows-6">
                            <div className="col-span-1 row-span-1 col-start-1 row-start-1 animate-bounce- text-6xl flex flex-row items-start justify-start translate-y-4 pl-4 w-[120%]"><img src={Hey} /></div>
                            <div className="col-span-1 row-span-1 col-start-6 row-start-1 animate-bounce- text-6xl flex flex-row items-start justify-start -translate-x-8 translate-y-6 pl-6 w-[120%]"><img src={Xoxo} /></div>
                            <div className="col-span-1 row-span-1 col-start-4 row-start-5 animate-bounce- text-6xl flex flex-row items-start justify-start translate-x-12 translate-y-16 pl-6 w-[250%]"><img src={Talk} /></div>

                            <div className="col-span-1 row-span-1 col-start-1 row-start-6 animate-bounce- text-6xl flex flex-row items-start justify-start -translate-y-3 px-12 w-full h-full">✨</div>
                            <div className="col-span-1 row-span-1 col-start-1 row-start-5 animate-bounce- text-6xl flex flex-row items-start justify-end w-full h-full">👀</div>
                            <div className="col-span-1 row-span-1 col-start-1 row-start-4 animate-bounce- text-6xl flex flex-row items-start justify-start px-12 w-full h-full">❤️</div>
                            <div className="col-span-1 row-span-1 col-start-4 row-start-6 animate-bounce- text-6xl flex flex-row items-start justify-end -translate-y-3 px-12 w-full h-full">💀</div>
                            <div className="col-span-1 row-span-1 col-start-6 row-start-5 animate-bounce- text-6xl flex flex-row items-start justify-end -translate-y-3 px-12 w-full h-full">😭</div>


                        </div>
                        <div className="md:shadow-inner w-full h-full flex flex-col grow-1 items-center justify-start pt-16"   >
                            <div className="relative w-[22.875rem] max-w-full">

                                <img src={Landing} alt="" className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl" />

                                <div className="z-50 absolute mx-auto w-[200px] h-[153px] bg-white object-cover object-start rounded-lg overflow-hidden top-0 right-0 translate-x-[150%] translate-y-8 shadow-xl border border-gray-200">
                                    <img src={Video1} alt="" className="w-[200px] h-[150px] object-cover object-start rounded-lg" />
                                    <div className="absolute bottom-0 right-0 px-3 py-2">
                                        <div className="flex flex-row items-center justify-center w-5">
                                            <i className="fa-brands fa-youtube" style={{ color: '#ff0505' }}></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="z-40 absolute mx-auto w-[200px] h-[153px] bg-white opacity-80 object-cover object-start rounded-lg overflow-hidden top-0 right-0 translate-x-[160%] translate-y-20 shadow border border-gray-200">
                                    <img src={Video2} alt="" className="w-[200px] h-[150px] object-cover object-start rounded-lg" />
                                    <div className="absolute bottom-0 right-0 px-3 py-2">
                                        <div className="flex flex-row items-center justify-center w-5">
                                            <i className="fa-brands fa-youtube" style={{ color: '#ff0505' }}></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="z-40 absolute mx-auto w-[218px] px-2 py-1 h-[156px] items-center justify-center bg-white  object-cover object-start rounded-lg overflow-hidden top-[50%] right-0 translate-x-[150%] -translate-y-20 shadow border border-gray-200">
                                    <img src={Article} alt="" className="w-[200px] h-[150px] object-cover object-start rounded-lg" />
                                </div>
                                <div className="z-30 absolute mx-auto w-[218px] px-2 py-1 h-[296px] text-xs items-center justify-center bg-white opacity-80 object-cover object-start rounded-lg overflow-hidden top-[50%] right-0 translate-x-[160%] -translate-y-14 shadow border border-gray-200">
                                    <div className="">
                                        Paris Fashion Week is only halfway through, but Madeline Argy has already picked up a pro tip for all the sartorial and social marathoners among us: “I now carry gum with me everywhere, because you have to sit very close to people,” the TikTokker and podcaster says to me over the phone this week. “There’s always going to be a girl that can offer you gum, but it’s nice to be the girl with the gum.”

                                        We’re speaking the afternoon after Argy attended the Saint Laurent show, which she decrees is “my favorite show that I’ve ever been to … the Eiffel Tower lit up perfectly right as it started...
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div className="hidden md:flex md:flex-col relative w-full h-full">
                        <div className="absolute w-full h-full grid grid-cols-6 grid-rows-6">
                            <div className="col-span-1 row-span-1 col-start-1 row-start-1 animate-bounce- text-6xl flex flex-row items-start justify-start translate-y-12 pl-6 w-[120%]"><img src={Hey} /></div>
                            <div className="col-span-1 row-span-1 col-start-6 row-start-1 animate-bounce- text-6xl flex flex-row items-start justify-start -translate-x-8 translate-y-6 pl-6 w-[120%]"><img src={Xoxo} /></div>
                            <div className="col-span-1 row-span-1 col-start-4 row-start-5 animate-bounce- text-6xl flex flex-row items-start justify-start translate-x-12 translate-y-16 pl-6 w-[250%]"><img src={Talk} /></div>

                            <div className="col-span-1 row-span-1 col-start-1 row-start-6 animate-bounce- text-6xl flex flex-row items-start justify-start -translate-y-3 px-12 w-full h-full">✨</div>
                            <div className="col-span-1 row-span-1 col-start-1 row-start-5 animate-bounce- text-6xl flex flex-row items-start justify-end w-full h-full">👀</div>
                            <div className="col-span-1 row-span-1 col-start-1 row-start-4 animate-bounce- text-6xl flex flex-row items-start justify-start px-12 w-full h-full">❤️</div>
                            <div className="col-span-1 row-span-1 col-start-4 row-start-6 animate-bounce- text-6xl flex flex-row items-start justify-end -translate-y-3 px-12 w-full h-full">💀</div>
                            <div className="col-span-1 row-span-1 col-start-6 row-start-5 animate-bounce- text-6xl flex flex-row items-start justify-end -translate-y-3 px-12 w-full h-full">😭</div>


                        </div>
                        <div className="mt-16 sm:mt-24 lg:mt-0 shadow-inner w-full h-full flex flex-col grow-1 items-center pr-[278px] justify-start pt-16"   >
                            <div className="relative  w-[22.875rem] max-w-full">

                                <img src={Landing} alt="" className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl" />

                                <div className="z-50 absolute mx-auto w-[200px] h-[153px] bg-white object-cover object-start rounded-lg overflow-hidden top-0 right-0 translate-x-[150%] translate-y-8 shadow-xl border border-gray-200">
                                    <img src={Video1} alt="" className="w-[200px] h-[150px] object-cover object-start rounded-lg" />
                                    <div className="absolute bottom-0 right-0 px-3 py-2">
                                        <div className="flex flex-row items-center justify-center w-5">
                                            <i className="fa-brands fa-youtube" style={{ color: '#ff0505' }}></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="z-40 absolute mx-auto w-[200px] h-[153px] bg-white opacity-80 object-cover object-start rounded-lg overflow-hidden top-0 right-0 translate-x-[160%] translate-y-20 shadow border border-gray-200">
                                    <img src={Video2} alt="" className="w-[200px] h-[150px] object-cover object-start rounded-lg" />
                                    <div className="absolute bottom-0 right-0 px-3 py-2">
                                        <div className="flex flex-row items-center justify-center w-5">
                                            <i className="fa-brands fa-youtube" style={{ color: '#ff0505' }}></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="z-40 absolute mx-auto w-[218px] px-2 py-1 h-[156px] items-center justify-center bg-white  object-cover object-start rounded-lg overflow-hidden top-[50%] right-0 translate-x-[150%] -translate-y-20 shadow border border-gray-200">
                                    <img src={Article} alt="" className="w-[200px] h-[150px] object-cover object-start rounded-lg" />
                                </div>
                                <div className="z-30 absolute mx-auto w-[218px] px-2 py-1 h-[296px] text-xs items-center justify-center bg-white opacity-80 object-cover object-start rounded-lg overflow-hidden top-[50%] right-0 translate-x-[160%] -translate-y-14 shadow border border-gray-200">
                                    <div className="">
                                        Paris Fashion Week is only halfway through, but Madeline Argy has already picked up a pro tip for all the sartorial and social marathoners among us: “I now carry gum with me everywhere, because you have to sit very close to people,” the TikTokker and podcaster says to me over the phone this week. “There’s always going to be a girl that can offer you gum, but it’s nice to be the girl with the gum.”

                                        We’re speaking the afternoon after Argy attended the Saint Laurent show, which she decrees is “my favorite show that I’ve ever been to … the Eiffel Tower lit up perfectly right as it started...
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
