'use client';
import React, { useState, useEffect, useRef } from 'react';
import { getFunctions, httpsCallable, } from "firebase/functions";
import { useAuthContext } from "../AuthContextProvider.js";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { doc, getDoc, getDocs, deleteDoc, collection, onSnapshot, getFirestore, addDoc, Timestamp } from "firebase/firestore";
import { useLocation } from "wouter";
import { useScreenshot } from 'use-react-screenshot'
import Cat from '../assets/images/cat.png';
import GoldenRetriever from '../assets/images/golden_retriever.png';
import Dobermann from '../assets/images/dobermann.png';
import Horse from '../assets/images/horse.png';
import HeartLetter from '../assets/images/HeartLetter.png';
import Capricorn from '../assets/starsigns/capricorn.svg';
import Pisces from '../assets/starsigns/pisces.svg';


import DA1 from '../assets/images/da1.png';
import DA2 from '../assets/images/da2.png';
import DA3 from '../assets/images/da3.png';
import DA4 from '../assets/images/da4.png';
import DA5 from '../assets/images/da5.png';
import DA6 from '../assets/images/da6.png';

import ProfilePicture_1 from '../assets/images/matches/0.png';
import ProfilePicture_2 from '../assets/images/matches/1.png';
import ProfilePicture_3 from '../assets/images/matches/2.png';
import ProfilePicture_4 from '../assets/images/matches/3.png';
import ProfilePicture_5 from '../assets/images/matches/4.png';
import ProfilePicture_6 from '../assets/images/matches/5.png';
import ProfilePicture_7 from '../assets/images/matches/6.png';

const matchData = {
    name: '',
    age: 0,
    profession: '',
    location: '',
    starSign: '',
    animalType: '',
    height: '',
    bodyType: '',
    netWorth: 0,
    voideId: '',
    matchPercentage: 0,
}

export default function Home(props) {
    console.log(props);
    const { user, loading } = useAuthContext()
    const [location, setLocation] = useLocation();

    const ref = useRef(null)
    const [image, takeScreenshot] = useScreenshot()
    const getImage = () => takeScreenshot(ref.current)

    const functions = getFunctions();
    const storage = getStorage();
    const db = getFirestore();
    const windowRef = useRef(null)

    const [isAudioCtaVisible, setIsAudioCtaVisible] = useState(false);

    const [currentItem, setCurrentItem] = useState(0);
    const scrollContainerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const scrollContainer = scrollContainerRef.current;
            const scrollPosition = scrollContainer.scrollLeft;
            const itemWidth = scrollContainer.offsetWidth * 0.9; // Assuming each item is 90% width of the container
            const currentIndex = Math.round(scrollPosition / itemWidth);
            setCurrentItem(currentIndex);
        };

        const scrollContainer = scrollContainerRef.current;
        scrollContainer.addEventListener('scroll', handleScroll);

        return () => {
            scrollContainer.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const share = async () => {
        await getImage();
        const file = new File(
            [Uint8Array.from(btoa(image), (m) => m.codePointAt(0))],
            'myMatch.png',
            { type: 'image/png' }
        );

        const filesArray = [file];
        const shareData = {
            title: `${'My Match'}`,
            files: filesArray,
            url: document.location.origin
        };

        navigator.share({
            title: 'Example Title',
            text: 'Check out this example.',
            url: window.location.href
        }).then(() => {
            console.log('Thanks for sharing!');
        }).catch((error) => {
            console.error('Error sharing:', error);
        });

    }

    console.log(window)

    return (
        <div className="relative w-full h-[100dvh] bg-gradient-to-b from-gray-100 via-gray-100 to-gray-200">
            <div className="relative h-full w-full flex flex-col justify-start overflow-scroll-y ">
                <div ref={ref} className="h-full relative w-full bg-white">
                    <div className="h-[80%] absolute inset-0 bg-gradient-to-b from-purple-600/60 via-pink-500/40 to-sky-300/80" />
                    <div className=" absolute inset-0 text-slate-900/[0.1] [mask-image:linear-gradient(to_bottom_left,white,white,transparent)]"><svg className="absolute inset-0 h-full w-full" xmlns="http://www.w3.org/2000/svg"><defs><pattern id="grid-bg" width="32" height="32" patternUnits="userSpaceOnUse" x="100%" patternTransform="translate(0 -1)"><path d="M0 32V.5H32" fill="none" stroke="currentColor"></path></pattern></defs><rect width="100%" height="100%" fill="url(#grid-bg)"></rect></svg></div>


                    <div className="relative w-full flex flex-col px-4 py-2 pt-2 items-center">
                        <div className="text-white/90 text-xl font-semibold leading-none">We found your type</div>
                        <div className="flex flex-row w-full justify-center pt-2">
                            <div className="text-white/50 text-xs font-bold leading-none">3 MATCHES FOUND</div>
                        </div>
                    </div>

                    <div ref={scrollContainerRef}
                        className="relative w-[100vw] flex flex-row items-center justify-start pt-2 pb-4  snap-x snap-mandatory overflow-x-scroll">

                        <div className={`${currentItem === 0 ? 'rotate-0 scale-100' : '-rotate-3 scale-95'} relative transition-all ease-in-out duration-[1000ms] shrink-0 w-[90%] px-2 snap-always snap-center first:pl-3 last:pr-3`}>
                            <div className="relative w-full h-[480px] bg-white rounded-md shadow-lg ">
                                <div className={`${currentItem === 0 ? 'opacity-0 z-20' : 'z-20 opacity-50'} rounded-t-md  bg-black transition-all duration-[500ms] absolute top-0 left-0 h-full w-full`} />

                                <img src={ProfilePicture_1} className="relative h-full w-full object-cover rounded-md" />
                                <div className="absolute top-0 right-0 flex flex-row pr-2 pt-2">
                                    <div className="rounded-md border-2 border-purple-600 bg-purple-500/80  text-white text-xs font-semibold px-1 py-1">87% Match</div>
                                </div>
                                <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-gray-900 via-gray-900/80 to-transparent text-white/90 p-2">
                                    <div className="font-semibold text-3xl">Sam, 29</div>
                                    <div className="text-sm">📍 Teacher from San Diego</div>
                                    <div className="text-sm">♓️ Pisces</div>
                                </div>

                            </div>
                            <div className="relative w-full flex flex-row justify-between px-3 pt-1- bg-white">
                                <div className={`${currentItem === 0 ? 'opacity-0 z-20' : 'z-20 opacity-50'} bg-black transition-all duration-[500ms] absolute top-0 left-0 h-full w-full`} />

                                {Array.from({ length: 12 }).map((_, i) => (
                                    <div key={i} className="relative h-[7px] w-[12px] border-b border-orange-400 rounded-b-full bg-gradient-to-b from-black via-yellow-700 to-yellow-800"></div>
                                ))}
                            </div>
                            <div className="relative bg-white rounded-b-xl">
                                <div className={`${currentItem === 0 ? 'opacity-0 z-20' : 'z-20 opacity-50'} rounded-b-xl  bg-black transition-all duration-[500ms] absolute top-0 left-0 h-full w-full`} />

                                <div className="relative pb-2 px-2 space-x-2 flex flex-row items-center">
                                    <div className="h-full flex flex-col items-end justify-center pt-1">
                                        <img src={GoldenRetriever} className="h-10 w-10 rounded-full " />
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="text-lg font-semibold">Golden Retriever</div>
                                        <div className="text-xs italic">"Loyal, friendly, and very affectionate"</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${currentItem === 1 ? 'rotate-0 scale-100' : currentItem > 1 ? '-rotate-3 scale-95 opacity-90' : 'rotate-3 scale-95'} transition-all ease-in-out duration-[750ms] shrink-0 w-[90%] px-2 snap-always snap-center first:pl-3 last:pr-3`}>
                            <div className="relative w-full h-[480px] bg-white rounded-md shadow-lg ">
                                <div className={`${currentItem === 1 ? 'opacity-0 z-20' : 'z-20 opacity-50'} rounded-t-md  bg-black transition-all duration-[500ms] absolute top-0 left-0 h-full w-full`} />

                                <img src={ProfilePicture_3} className="relative h-full w-full object-cover rounded-md" />
                                <div className="absolute top-0 right-0 flex flex-row pr-2 pt-2">
                                    <div className="rounded-md border border-purple-600 bg-purple-500/80  text-white text-xs font-semibold px-1 py-1">71% Match</div>
                                </div>
                                <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-gray-900 via-gray-900/80 to-transparent text-white/90 p-2">
                                    <div className="font-semibold text-3xl">Sam, 30</div>
                                    <div className="text-sm">📍 Financial Analyst from Boston</div>
                                    <div className="text-sm">♊️ Gemini</div>
                                </div>

                            </div>
                            <div className="relative w-full flex flex-row justify-between px-3 pt-1- bg-white">
                                <div className={`${currentItem === 1 ? 'opacity-0 z-20' : 'z-20 opacity-50'}  bg-black transition-all duration-[500ms] absolute top-0 left-0 h-full w-full`} />

                                {Array.from({ length: 12 }).map((_, i) => (
                                    <div key={i} className="relative h-[7px] w-[12px] border-b border-orange-400 rounded-b-full bg-gradient-to-b from-black via-yellow-700 to-yellow-800"></div>
                                ))}
                            </div>
                            <div className="relative bg-white rounded-b-xl">
                                <div className={`${currentItem === 1 ? 'opacity-0 z-20' : 'z-20 opacity-50'} rounded-b-xl bg-black transition-all duration-[500ms] absolute top-0 left-0 h-full w-full`} />

                                <div className="relative pb-2 px-2 space-x-2 flex flex-row items-center">
                                    <div className="h-full flex flex-col items-end justify-center pt-1">
                                        <img src={Dobermann} className="h-10 w-10 rounded-full " />
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="text-lg font-semibold">Dobermann</div>
                                        <div className="text-xs italic">"Motivated, responsible, and very protective"</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${currentItem === 2 ? 'rotate-0 scale-100' : 'rotate-3 scale-95'} transition-all ease-in-out duration-[750ms] shrink-0 w-[90%] px-2 snap-always snap-center first:pl-3 last:pr-3`}>
                            <div className="relative w-full h-[480px] bg-white rounded-md shadow-lg ">
                                <div className={`${currentItem === 2 ? 'opacity-0 z-20' : 'z-20 opacity-50'} rounded-t-md  bg-black transition-all duration-[500ms] absolute top-0 left-0 h-full w-full`} />

                                <img src={ProfilePicture_6} className="relative h-full w-full object-cover rounded-md" />
                                <div className="absolute top-0 right-0 flex flex-row pr-2 pt-2">
                                    <div className="rounded-md border-2 border-purple-600 bg-purple-500/80  text-white text-xs font-semibold px-1 py-1">65% Match</div>
                                </div>
                                <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-gray-900 via-gray-900/80 to-transparent text-white/90 p-2">
                                    <div className="font-semibold text-3xl">Jay, 32</div>
                                    <div className="text-sm">📍 Doctor from Washington</div>
                                    <div className="text-sm">♌️ Leo</div>
                                </div>

                            </div>
                            <div className="relative w-full flex flex-row justify-between px-3 pt-1- bg-white">
                                <div className={`${currentItem === 2 ? 'opacity-0 z-20' : 'z-20 opacity-50'} bg-black transition-all duration-[500ms] absolute top-0 left-0 h-full w-full`} />

                                {Array.from({ length: 12 }).map((_, i) => (
                                    <div key={i} className="relative h-[7px] w-[12px] border-b border-orange-400 rounded-b-full bg-gradient-to-b from-black via-yellow-700 to-yellow-800"></div>
                                ))}
                            </div>
                            <div className="relative bg-white rounded-b-xl">
                                <div className={`${currentItem === 2 ? 'opacity-0 z-20' : 'z-20 opacity-50'} rounded-b-xl  bg-black transition-all duration-[500ms] absolute top-0 left-0 h-full w-full`} />

                                <div className="relative pb-2 px-2 space-x-2 flex flex-row items-center">
                                    <div className="h-full flex flex-col items-end justify-center pt-1">
                                        <img src={Horse} className="h-10 w-10 rounded-full " />
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="text-lg font-semibold">Horse</div>
                                        <div className="text-xs italic">"Neighhhh?"</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    {/* // // // // // */}

                    {/* INTERESTS SECTION */}
                    <div className="relative w-full border-b border-white bg-white flex flex-col pt-2 pb-6 ">
                        <div className="w-full flex flex-row items-start justify-start px-4 pt-1">
                            <div className="font-semibold text-gray-700 text-xs w-full text-start  ">
                                MORE ABOUT HIM
                            </div>
                        </div>

                        <div className="w-full grid grid-cols-3 gap-x-2 pt-2 px-2">
                            <div className="col-span-1 h-full w-full flex flex-col items-start justify-start px-4 pb-4 pt-1">
                                <div className="text-xl">{currentItem === 0 ? `6' 2"` : currentItem === 1 ? `6' 5"` : `6' 1"`}</div>
                                <div className="text-xs font-semibold">Height</div>
                            </div>

                            <div className="col-span-1 h-full w-full flex flex-col items-start justify-start px-4 pb-4 pt-1">
                                <div className="text-xl">{currentItem === 0 ? `Slim` : currentItem === 1 ? `Lean` : `Muscular`}</div>
                                <div className="text-xs font-semibold">Body Type</div>
                            </div>

                            <div className="col-span-1 h-full w-full flex flex-col items-start justify-start px-4 pb-4 pt-1">
                                <div className="text-xl">{currentItem === 0 ? `$286,000` : currentItem === 1 ? `$1,522,000` : `$719,000`}</div>
                                <div className="text-xs font-semibold">Net Worth</div>
                            </div>
                        </div>

                        <div className="w-full flex flex-col bg-white rounded-t-3xl overflow-hidden px-4">
                            <div className="w-full flex flex-col space-y-2">
                                <div className="font-semibold text-gray-700 text-xs w-full text-start  ">
                                    HEAR HIS VOICE
                                </div>
                                <div className="h-full bg-white px-3 py-1 space-x-2 rounded-lg shadow flex flex-row items-center border border-violet-300/80">
                                    <div onClick={() => { setIsAudioCtaVisible(!isAudioCtaVisible) }} className="">
                                        <icon className="fa-solid fa-play text-violet-400" />
                                    </div>
                                    <div className="w-full flex flex-row items-center space-x-[2px]">
                                        <div className="w-1 h-5 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-4 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-2 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-1 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-5 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-5 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-2 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-1 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-2 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-2 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-3 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-1 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-5 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-4 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-5 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-1 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-2 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-2 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-4 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-2 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-1 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-5 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-5 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-5 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-5 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-2 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-2 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-2 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-2 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-4 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-2 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-1 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-5 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-5 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-2 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-5 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-4 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-2 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-1 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-5 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-5 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-2 rounded-full bg-violet-200"></div>
                                        <div className="w-1 h-1 rounded-full bg-violet-200"></div>
                                    </div>
                                    <div className="text-violet-400 font-semibold">00:15</div>
                                </div>

                            </div>

                        </div>



                    </div>


                </div>



                <div className="w-full px-4 flex flex-col pb-4">
                    <div className="flex flex-row space-x-1 items-center pb-1">
                        <div className="-translate-y-[1px]">🔒</div>
                        <div className="text-sm font-bold text-gray-900">Private</div>
                    </div>
                    <div className="rounded-2xl bg-gray-200 w-full flex flex-col px-4 py-3">
                        <div className="font-bold text-sm text-gray-900">WHAT HE'S LOOKING FOR</div>
                        <div className="w-full flex flex-row pt-1 items-center">
                            <div className="max-h-12 h-12 min-w-12 max-w-12 text-xl bg-white rounded-full flex flex-row items-center justify-center">🍆</div>
                            <div className="pl-2 leading-tight text-sm">He wants to jump straight into it and start talking intimately & dirty</div>
                        </div>
                        <div className="w-full pt-1">
                            <div className="w-full rounded-full bg-purple-600 flex flex-row items-center justify-center text-white text-md font-semibold px-2 py-1 mt-2">Start Chatting</div>
                        </div>
                    </div>
                </div>

                <div>
                    <button style={{ marginBottom: '10px' }} onClick={share}>
                        Share
                    </button>
                </div>

                {/* = = = = = = = = = = = = = = = = = = = = = = */}


            </div>
        </div >
    );
}


{/* ROMANCE AND FLIRTINESS SECTION */ }

{/*



*/}