'use client';
import React, { useState, useEffect, useRef } from 'react';
import { getFunctions, httpsCallable, } from "firebase/functions";
import { useAuthContext } from "../AuthContextProvider.js";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { doc, getDoc, getDocs, deleteDoc, collection, onSnapshot, getFirestore, addDoc, Timestamp } from "firebase/firestore";
import { useLocation } from "wouter";
import { DndContext, closestCenter, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import axios from 'axios';
import ProgressHeader from '../components/ProgressHeader.js';
import QuestionCard from '../components/QuestionCard.js';
import ChatInterface from '../components/ChatInterface.js';
import Loader from '../components/Loader.js';
import bg1 from '../assets/images/bg1.png'
import SwipeableCards from '../components/SwipeableCards.js';
import Draggable from '../components/Draggable';
import Droppable from '../components/Droppable';
import SortableList from '../components/SortableList';
import ItemBank from '../components/ItemBank';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import Hiking from '../assets/images/hiking.jpg';
import Concert from '../assets/images/Concert.png';
import Basketball from '../assets/images/Basketball.png';
import Lipstick from '../assets/images/lipstick.png';
import Lipgloss from '../assets/images/lipgloss.png';
import Polaroid from '../components/Polaroid.js';
import { set } from 'lodash';
import RatingImg from '../assets/images/81.png';


const items = [
    { id: '1', text: 'Item 1' },
    { id: '2', text: 'Item 2' },
    { id: '3', text: 'Item 3' },
];

const questionTypes = ['option', 'scale', 'rank', 'boolean'];

const headers = ['Learning the basics 🌸', 'Values and beliefs 🌟', 'Interests and hobbies 🌈', 'Romantic preferences 💕'];
const subHeaders = ['First off, let\'s get to know some basics about you and your personality!', 'Next, let\'s dive into your values and beliefs by swiping! Left for red flag, and right for green.', 'Now, let\'s explore your interests and hobbies.', 'Finally, let\'s talk about your romantic preferences.'];
const timeEstimates = [5, 3, 5, 2];

const traits = ['Has a large social media following', 'Is a workaholic', 'Has a very close relationship with family', 'Is a neat freak', 'Has a dark sense of humour', 'Belives in astrology', 'Games on the weekends', 'Does not want children', 'Their ex was more than 7 years younger than them', 'Has facial hair', 'Is a vegan or vegetarian', 'Is politically active', "Owns some Bitcoin (but doesn't mention it unless asked)", 'Is a furry (but keeps it private)', 'Has a favorite reality tv show'];

const polaroids = [
    { question: 'Going hiking', image: Hiking, subtitle: 'Spending time going for long walks in nature' },
    { question: 'Going to a concert', image: Concert, subtitle: 'Spending time at a live music event' },
    { question: 'Watching basketball', image: Basketball, subtitle: 'Spending time watching a basketball match' },
]
export default function Home(props) {
    console.log(props);
    const { user, loading } = useAuthContext()
    const [location, setLocation] = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    const [questions, setQuestions] = useState([]);
    const [flags, setFlags] = useState([]);
    const [ratings, setRatings] = useState([]);
    const [rankings, setRankings] = useState([]);

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor)
    );

    const functions = getFunctions();
    const storage = getStorage();
    const db = getFirestore();

    const getQuestions1 = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get('https://script.googleusercontent.com/a/macros/etherzaar.com/echo?user_content_key=cNuO_gZ_FC19oyrcFJ1gD8lWDi1xyv8i09J3Cb3FUE4FMj5_Z7hKKysgPWsdfWYrOXtPsBoBQtkm0pTcLRQp884RnM1ZUDyZOJmA1Yb3SEsKFZqtv3DaNYcMrmhZHmUMi80zadyHLKAzEcn-2rtxSRrK4v8bqeWdS4chwneYR2tMXbdqm590s0gmyJSrUvtynQiYIE_fcmw_VmgRMymFiFIpjp3WTswKwiYaBgcy1S0yjUcGF_dq86_TKGz69Tunu57jfqaDjP4&lib=MQgkZCuigb23_Ly1C9F8ReUrQvdIE2eQP');
                console.log(response.data);
                resolve(response.data);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        })
    }

    useEffect(() => {
        // Load cache first

        const cachedData = localStorage.getItem('questions');
        if (cachedData) {
            const data = JSON.parse(cachedData);
            setQuestions(data?.QUESTIONS);
            setFlags(data?.FLAGS);
            setRatings(data?.RATINGS);
            setRankings(data?.RANKINGS);
            setIsLoading(false);
        };
        getQuestions1().then(result => {
            localStorage.setItem('questions', JSON.stringify(result));
            setQuestions(result?.QUESTIONS);
            setFlags(result?.FLAGS);
            setRatings(result?.RATINGS);
            setRankings(result?.RANKINGS);
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
    }, [])

    const windowRef = useRef(null)

    const [currentSection, setCurrentSection] = useState(0);
    const [currentStep, setCurrentStep] = useState(0);

    const [name, setName] = useState('');

    const [answers, setAnswers] = useState([]);

    const swipeRef = useRef(null);

    const handleSwipe = (direction) => {
        if (swipeRef.current) {
            swipeRef.current(direction); // Assuming you want to swipe the first card
        }
    };

    const containers = ['1st Priority', '2nd Priority', '3rd Priority'];
    const [parent, setParent] = useState(null);

    const options = ['Option A', 'Option B', 'Option C'];
    const [children, setChildren] = useState(options.map(o => { return { id: o, parent: 'startzone', active: false } }));

    const draggableMarkup = (id) => (
        <Draggable id={id} className="p-2 border border-gray-300 bg-white shadow"><div className="p-2 border border-gray-300 bg-white shadow" style={{ touchAction: 'manipulation' }}>Drag me</div></Draggable>
    );

    console.log('children', children)
    function handleDragEnd(event) {
        const { over } = event;
        console.log('over', over)

        if (over) {
            const parent = over.id;
            const child = event.active.id;

            // Check if container already has a child
            let currentChild = children.find(c => c.parent === parent);
            console.log('currentChild', currentChild)

            setChildren(children.map(c => {
                if (c.id === child) {
                    return { ...c, parent }
                } else if (currentChild?.parent === parent) {
                    return { ...c, parent: 'startzone' }
                } else {
                    return c;
                }
            }));
        } else {
            setChildren(children.map(c => c.id === event.active.id ? { ...c, parent: 'startzone' } : c));
        }
    }
    const selectAnswer = (type, answer) => {
        if (type == 'custom' && answer.length == 0) return;

        let userAnswer = {
            section: currentSection,
            question: questions[currentStep]?.question,
            type: 'option',
            isAiInput: type == 'custom',
            answer
        }

        setName(''); // Clear custom input
        setAnswers([...answers, userAnswer]);

        if (currentStep == questions?.length - 1) {
            setCurrentSection(currentSection + 1);
            setCurrentStep(0);
        } else {
            setCurrentStep(currentStep + 1);
        }
    }

    const setFlag = (flag, value) => {
        setAnswers([...answers, { section: currentSection, question: flags[currentStep], type: 'flag', flag, isAiInput: false, answer: value }]);
        if (currentStep == flags?.length - 1) {
            setCurrentSection(currentSection + 1);
            setCurrentStep(0);
        } else {
            setCurrentStep(currentStep + 1);
        }
    }

    useEffect(() => {
        if (currentSection == 0) {
            windowRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }, [currentSection, currentStep])

    const ratePolaroid = (rating) => {
        setAnswers([...answers, { section: currentSection, question: ratings[currentStep]?.question, type: 'rating', isAiInput: false, answer: rating }]);
        if (currentStep == ratings?.length - 1) {
            setCurrentSection(currentSection + 1);
            setCurrentStep(0);
        } else {
            setCurrentStep(currentStep + 1);
        }
    }

    const resetChildren = () => {
        setChildren(options.map(o => { return { id: o, parent: 'startzone', active: false } }));
    }

    const confirmOrder = () => {
        console.log('answers', answers)

    }
    console.log('children', children)
    console.log('ANSWERS', answers);

    return (
        <div className="relative w-full h-[100dvh] bg-white ">
            <div className="h-[100%] absolute inset-0 bg-gradient-to-b from-purple-600/50 via-pink-500/40 to-sky-500/60" />
            <div className=" absolute inset-0 text-slate-900/[0.1] [mask-image:linear-gradient(to_bottom_left,white,white,transparent)]"><svg className="absolute inset-0 h-full w-full" xmlns="http://www.w3.org/2000/svg"><defs><pattern id="grid-bg" width="32" height="32" patternUnits="userSpaceOnUse" x="100%" patternTransform="translate(0 -1)"><path d="M0 32V.5H32" fill="none" stroke="currentColor"></path></pattern></defs><rect width="100%" height="100%" fill="url(#grid-bg)"></rect></svg></div>

            <div className={`${isLoading ? 'z-[100] opacity-100' : 'z-[100] opacity-0'} duration-700 pointer-events-none transition-all absolute top-0 left-0 h-full w-full overflow-hidden flex flex-row`}>
                <div className="bg-gradient-to-br from-gray-800 via-purple-800 to-gray-800 h-full w-full flex flex-col items-center justify-center">
                    <Loader />
                </div>
            </div>

            <div className="relative h-full w-full flex flex-col justify-start overflow-hidden ">

                {/*
                        <ProgressHeader currentSection={currentSection} currentStep={currentStep} allSteps={currentSection === 0 ? questions : currentSection === 1 ? flags : currentSection === 2 ? ratings : rankings} />
                    */}

                <div className=" w-full flex flex-row justify-between items-center pt-2 pb-2 px-4 bg-purple-300 border-b-2 border-black ">
                    <div className="border-2 border-black flex flex-row justify-evenly w-full">
                        {questions.concat(flags).concat(ratings).map((step, index) => {
                            let bgColor = 'bg-violet-100';

                            if (index < questions.length) {
                                bgColor = currentStep >= index || currentSection > 0 ? 'bg-purple-400' : 'bg-violet-100';
                            } else if (index < questions.length + flags.length) {
                                bgColor = Boolean(currentStep + questions.length >= index && currentSection > 0) || currentSection > 1 ? 'bg-pink-300' : 'bg-violet-100';
                            } else if (Boolean(index < questions.length + flags.length + ratings.length && currentSection > 1)) {
                                bgColor = currentStep + questions.length + flags.length >= index || currentSection > 2 ? 'bg-sky-300' : 'bg-violet-100';
                            }

                            return (
                                <div
                                    key={index}
                                    className={`${bgColor} ${currentStep === index ? 'current-step' : ''} border-r border-gray-300 h-6 w-full`}
                                />
                            );
                        })}
                    </div>

                </div>

                <div className={`${currentSection == 0 ? 'transition-all space-y-4' : currentSection == 1 ? 'h-full' : ''} z-20 px-4 pb-4`}>

                    {currentSection === 0 && (
                        <div className="relative w-full pt-8 px-4 flex flex-col space-y-6">

                            <div className="relative z-20 w-full flex flex-col items-center justify-start border-[2px] border-gray-800 ">
                                <div className="w-full flex flex-row items-center justify-between bg-purple-400 border-b-2 border-gray-800 pl-1 pr-[2px]">
                                    <div>Question?</div>
                                    <div className="flex flex-row items-center space-x-[2px]">
                                        <div className="h-5 w-5 pb-1 border border-black bg-purple-200 flex flex-row items-center justify-center font-bold">_</div>
                                        <div className="h-5 w-5 border border-black bg-purple-200 flex flex-row items-center justify-center font-bold">X</div>
                                    </div>
                                </div>
                                <div className="bg-purple-200 px-2 py-1 font-mono w-full border border-gray-300">
                                    {questions[currentStep]?.question}
                                </div>
                            </div>

                        </div>
                    )}
                    {currentSection === 0 && (
                        <div ref={windowRef} className="w-full h-0 flex flex-row items-center justify-start"></div>
                    )}
                    {currentSection === 1 && (
                        <SwipeableCards cards={flags} setFlag={setFlag} onSwipe={fn => swipeRef.current = fn} />
                    )}
                    {currentSection === 2 && (
                        <div className="h-full w-full flex flex-col space-y-2">

                            <Polaroid image={ratings[currentStep]?.image} annotation={ratings[currentStep]?.question} subtitle={ratings[currentStep]?.subtitle} />
                        </div>
                    )}

                </div>

                {/* RESPONSE SECTION */}

                {
                    currentSection == 0 && (
                        <div className="relative h-full flex flex-grow flex-col justify-end">

                            <div className="w-full space-y-4 px-8 py-8 transition-all flex flex-col">
                                <div className="relative z-20 w-full flex flex-col items-center justify-start border-[2px] border-gray-800 ">
                                    <div className="w-full flex flex-row items-center justify-between bg-sky-300 border-b-2 border-gray-800 pl-1 pr-[2px]">
                                        <div>Answer:</div>
                                        <div className="flex flex-row items-center space-x-[2px]">
                                            <div className="h-5 w-5 pb-1 border border-black bg-purple-200 flex flex-row items-center justify-center font-bold">_</div>
                                            <div className="h-5 w-5 border border-black bg-purple-200 flex flex-row items-center justify-center font-bold">X</div>
                                        </div>
                                    </div>
                                    <div className="px-4 py-4 space-y-3 bg-purple-200 w-full">

                                        {questions[currentStep]?.answers?.map((answer, index) => (
                                            <div className={`border-purple-500 border-2 w-full `}>
                                                <div onClick={() => {
                                                    selectAnswer('option', answer);
                                                }} className={`relative z-20  w-full flex flex-col items-center justify-start`}>
                                                    <div className={`bg-purple-50 w-full flex flex-row items-center justify-between pl-3 pr-[2px] py-4`}>
                                                        <div className="font-semibold">{answer}</div>
                                                    </div>

                                                    {/*
                                                    <div className={` px-2 py-1 font-mono w-full leading-tight flex flex-row`}>
                                                        <div className={``}>
                                                            {questions[currentStep]?.details[index]}
                                                        </div>
                                                    </div>
                                                */}

                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>


                            {/* ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ */}
                        </div>
                    )
                }

                {
                    currentSection == 1 && (
                        <div className=" w-full flex flex-grow flex-col justify-end">
                            <div className="w-full flex flex-row justify-between px-8 pb-8 ">
                                <div className="relative z-20 w-full flex flex-col items-center justify-start border-[2px] border-gray-800 ">
                                    <div className="w-full flex flex-row items-center justify-between bg-sky-300 border-b-2 border-gray-800 pl-1 pr-[2px]">
                                        <div>Answer:</div>
                                        <div className="flex flex-row items-center space-x-[2px]">
                                            <div className="h-5 w-5 pb-1 border border-black bg-purple-200 flex flex-row items-center justify-center font-bold">_</div>
                                            <div className="h-5 w-5 border border-black bg-purple-200 flex flex-row items-center justify-center font-bold">X</div>
                                        </div>
                                    </div>
                                    <div className="py-4 px-4 space-x-6 bg-purple-200 w-full flex flex-row justify-center items-center">
                                        <button className="px-8 py-4 bg-white border border-gray-900" style={{ boxShadow: '2px 2px 0px 1px black' }} onClick={() => handleSwipe('left')}>Red Flag</button>
                                        <button className="px-8 py-4 bg-white border border-gray-900" style={{ boxShadow: '2px 2px 0px 1px black' }} onClick={() => handleSwipe('right')}>Green Flag</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    currentSection == 2 && (
                        <div className="h-full w-full flex flex-grow flex-col justify-end items-center">
                            <div className="w-full flex flex-row justify-between px-8 pb-8 ">
                                <div className="relative z-20 w-full flex flex-col items-center justify-start border-[2px] border-gray-800 ">
                                    <div className="w-full flex flex-row items-center justify-between bg-sky-300 border-b-2 border-gray-800 pl-1 pr-[2px]">
                                        <div>Answer:</div>
                                        <div className="flex flex-row items-center space-x-[2px]">
                                            <div className="h-5 w-5 pb-1 border border-black bg-purple-200 flex flex-row items-center justify-center font-bold">_</div>
                                            <div className="h-5 w-5 border border-black bg-purple-200 flex flex-row items-center justify-center font-bold">X</div>
                                        </div>
                                    </div>
                                    <div className="w-full px-6 pt-4 pb-4 bg-white">
                                        <div className="bg-sky-100 font-mono border border-black inline-flex text-wrap w-full h-full pl-2 py-2 leading-tight">
                                            <span className="pr-2 h-full flex flex-col items-center justify-center"><img src={RatingImg} className="h-6 w-10" /></span>
                                            How strongly do you agree with the above statement?
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-row justify-between items-center px-6 pb-8 bg-white ">
                                        <div className="rounded-2xl" style={{ boxShadow: '3px 3px 0px 0px gainsboro' }}>
                                            <div onClick={() => { ratePolaroid(-2) }} className="relative h-12 w-10 rounded-2xl border-2 border-purple-300 bg-purple-100 flex flex-row items-center justify-center">
                                                <div className="absolute top-0 left-0 translate-x-[3px] -translate-y-[2px] font-bold text-purple-500">
                                                    -
                                                </div>
                                                <icon className="relative fa-regular fa-thumbs-down fa-flip-horizontal text-purple-500" />
                                            </div>
                                        </div>
                                        <div className="h-1 w-2 bg-purple-100" />
                                        <div className="rounded-2xl" style={{ boxShadow: '3px 3px 0px 0px gainsboro' }}>
                                            <div onClick={() => { ratePolaroid(-1) }} className="h-12 w-10 rounded-2xl border-2 border-purple-200 bg-purple-50 flex flex-row items-center justify-center">
                                                <icon className="fa-regular fa-thumbs-down fa-flip-horizontal text-purple-500" />
                                            </div>
                                        </div>
                                        <div className="h-1 w-2 bg-purple-100" />
                                        <div className="rounded-2xl" style={{ boxShadow: '3px 3px 0px 0px gainsboro' }}>
                                            <div onClick={() => { ratePolaroid(0) }} className="h-12 w-10 rounded-2xl border-2 border-gray-200 bg-gray-100 flex flex-row items-center justify-center" >
                                                <icon className="fa-regular fa-face-diagonal-mouth text-gray-400" />
                                            </div>
                                        </div>
                                        <div className="h-1 w-2 bg-green-100" />
                                        <div className="rounded-2xl" style={{ boxShadow: '3px 3px 0px 0px gainsboro' }}>
                                            <div onClick={() => { ratePolaroid(1) }} className="h-12 w-10 rounded-2xl border-2 border-green-200 bg-green-50 flex flex-row items-center justify-center" >

                                                <icon className="relative fa-regular fa-thumbs-up text-green-500" />
                                            </div>
                                        </div>
                                        <div className="h-1 w-2 bg-green-100" />
                                        <div className="rounded-2xl" style={{ boxShadow: '3px 3px 0px 0px gainsboro' }}>
                                            <div onClick={() => { ratePolaroid(2) }} className="relative h-12 w-10 rounded-2xl border-2 border-green-300 bg-green-100 flex flex-row items-center justify-center" >
                                                <div className="absolute top-0 right-0 -translate-x-[3px] -translate-y-[2px] font-bold text-green-500">
                                                    +
                                                </div>
                                                <icon className="relative fa-regular fa-thumbs-up text-green-500" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                {/* = = = = = = = = = = = = = = = = = = = = = = */}


            </div >
        </div >
    );
}
