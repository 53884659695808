import React from 'react';

const Polaroid = ({ image, annotation, subtitle }) => {
    return (
        <div className="relative inline-block p-4">
            <div className="w-full h-full border-2 p-2 bg-white border-black rounded-sm">
                <div className="overflow-hidden">
                    <img
                        src={image}
                        alt="Polaroid"
                        className="w-full h-40 object-cover border border-gray-300 rounded-sm"
                    />
                    <div className="py-2">
                        <div className="font-semibold text-xl pb-1">{annotation}</div>
                        <div className="text-sm text-gray-800 leading-tight">{subtitle}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Polaroid;