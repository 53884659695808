import React, { useState, useEffect, useRef } from 'react';
import { useSprings, animated } from 'react-spring';
import { useDrag } from '@use-gesture/react';

const to = i => ({ x: 0, y: Math.round(i / 5) * -5, scale: 1, rot: -10 + Math.random() * 20, delay: 250 + (i * 50) });
const from = () => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });
const trans = (r, s, x, y) => `perspective(1500px) rotateX(30deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s}) translate3d(${x}px,${y}px,0)`;

function SwipeableCards({ cards, setFlag, onSwipe }) {
    const containerRef = useRef(null);
    const [gone] = useState(() => new Set());
    const [props, api] = useSprings(cards.length, i => ({ ...to(i), from: from() }));
    const [cardSize, setCardSize] = useState({ width: 300, height: 400 }); // Default card size

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setCardSize({
                    width: containerRef.current.offsetWidth * 0.9,
                    height: containerRef.current.offsetHeight * 0.9,
                });
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const bind = useDrag(({ args: [index], down, movement: [mx], direction: [xDir], velocity }) => {
        const trigger = Math.abs(mx) > 150 || velocity > 0.5;
        const dir = xDir < 0 ? -1 : 1;
        if (!down && trigger) gone.add(index);
        api.start(i => {
            if (index !== i) return;
            const x = down ? mx : trigger ? (200 + window.innerWidth) * dir : 0;
            const rot = down ? mx / 100 + (trigger ? dir * 10 * velocity : 0) : 0;
            const scale = down ? 1.1 : 1;
            return { x, rot, scale, delay: undefined, config: { friction: 50, tension: down ? 800 : 500 } };
        });
        if (!down && trigger) setFlag(cards[index], dir === -1 ? 'red' : 'green');
        if (!down && gone.size === cards.length) setTimeout(() => gone.clear() || api.start(i => to(i)), 600);
    });

    const swipe = (direction) => {
        const dir = direction === 'left' ? -1 : 1;
        // Determine the top-most card (the last in the list not yet gone)
        let topMostIndex = cards.length - 1;
        for (let i = cards.length - 1; i >= 0; i--) {
            if (!gone.has(i)) {
                topMostIndex = i;
                break;
            }
        }
        const index = topMostIndex;

        if (!gone.has(index)) {
            gone.add(index);
            setFlag(cards[index], dir === -1 ? 'red' : 'green');
            api.start(i => {
                if (index !== i) return;
                return {
                    x: (200 + window.innerWidth) * dir,
                    rot: dir * 10,
                    scale: 1,
                    delay: undefined,
                    config: { friction: 50, tension: 200 },
                };
            });
        }

        if (gone.size === cards.length) {
            setTimeout(() => {
                gone.clear();
                api.start(i => to(i));
            }, 600);
        }
    };

    // Make the swipe function available outside
    React.useEffect(() => {
        if (onSwipe) onSwipe(swipe);
    }, [onSwipe]);

    return (
        <div ref={containerRef} className="flex justify-center items-center h-full w-full pt-6 pb-6">
            {props.map(({ x, y, rot, scale }, i) => (
                <animated.div key={i} className="absolute" style={{ x, y, width: cardSize.width, height: cardSize.height }}>

                    <animated.div
                        {...bind(i)}
                        style={{
                            transform: x.to((x) => y.to((y) => rot.to((r) => scale.to((s) => trans(r, s, x, y))))),
                            touchAction: 'none',
                            backgroundColor: x < -50 ? 'red' : x > 50 ? 'green' : 'white',
                        }}
                        className="w-full h-full bg-white flex p-2 justify-center items-center border-2 border-gray-800"
                    >
                        <div className="w-full h-full p-5" style={{
                            backgroundImage: `url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm33.414-6l5.95-5.95L45.95.636 40 6.586 34.05.636 32.636 2.05 38.586 8l-5.95 5.95 1.414 1.414L40 9.414l5.95 5.95 1.414-1.414L41.414 8zM40 48c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zM9.414 40l5.95-5.95-1.414-1.414L8 38.586l-5.95-5.95L.636 34.05 6.586 40l-5.95 5.95 1.414 1.414L8 41.414l5.95 5.95 1.414-1.414L9.414 40z' fill='%239C92AC' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E")`,
                        }}>

                            <div className="w-full h-full bg-white border-2 border-gray-300 flex justify-center text-lg items-center text-center">
                                {cards[i]?.question}
                            </div>
                        </div>

                    </animated.div>

                </animated.div>
            ))}
        </div>
    );
}

export default SwipeableCards;
