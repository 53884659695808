import React, { useState, useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import styled from 'styled-components';

const Waveform = ({ audio, id, creatorView }) => {
    const containerRef = useRef();
    const waveSurferRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        if (!audio) return;

        const waveSurfer = WaveSurfer.create({
            container: containerRef.current,
            responsive: true,
            barWidth: 3,
            barHeight: 3,
            cursorWidth: 0,
            fillParent: true,
            width: 220,
            height: 30,
            autoplay: false,
            progressColor: '#3c82f6'
        });

        waveSurfer.load(audio);
        waveSurfer.on('ready', () => {
            waveSurferRef.current = waveSurfer;
        });

        waveSurfer.on('play', () => setIsPlaying(true));
        waveSurfer.on('pause', () => setIsPlaying(false));

        return () => {
            waveSurfer.destroy();
        };
    }, [audio]);

    const handlePlayPause = () => {
        const waveSurfer = waveSurferRef.current;
        if (waveSurfer) {
            waveSurfer.playPause();
        }
    };

    return (
        <div key={id} className={`${creatorView ? 'bg-white/75' : 'bg-gray-300'} flex flex-row items-center justify-center rounded-full`}>
            <div className="pr-4 py-2">

                <WaveSurferWrap>
                    <button
                        onClick={handlePlayPause}
                        type="button"
                        className="h-full flex items-center justify-center text-gray-700"
                    >
                        {isPlaying ? <i className="fa-solid fa-pause"></i> : <i className="fa-solid fa-play"></i>}
                    </button>
                    <div ref={containerRef} />
                </WaveSurferWrap>
            </div>

        </div>
    );
};

const WaveSurferWrap = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;

  button {
    border: none;
    padding: 0;
  }
`;

export default Waveform;
