import React, { useEffect, useState } from 'react';
import Waveform from './Waveform';

const AudioRecorder = (props) => {
    const [recording, setRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioURL, setAudioURL] = useState("");
    const [audioBlob, setAudioBlob] = useState(null);
    const [stream, setStream] = useState(null);
    const [elapsedTime, setElapsedTime] = useState(0);

    useEffect(() => {
        let interval;
        if (recording) {
            interval = setInterval(() => {
                setElapsedTime((prevTime) => {
                    if (prevTime < 10) {
                        return prevTime + 1;
                    } else {
                        stopRecording();
                        return 10;
                    }
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [recording]);

    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                setStream(stream); // Store the stream

                const recorder = new MediaRecorder(stream);
                setMediaRecorder(recorder);

                recorder.ondataavailable = e => {
                    setAudioBlob(e.data);
                    const url = URL.createObjectURL(e.data);
                    setAudioURL(url);
                };

                recorder.start();
                setRecording(true);
                setElapsedTime(0);
            })
            .catch(error => {
                console.error("Error accessing media devices.", error);
            });
    };

    const stopRecording = () => {
        if (mediaRecorder && mediaRecorder.state === "recording") {
            mediaRecorder.stop();
            setRecording(false);
            // Release the stream
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
        }
    };

    const submitVoice = () => {
        if (audioBlob && !recording) {
            props.onRecorded(audioBlob);
        }
    };

    return (
        <>
            <div className="z-50 w-full flex flex-col md:flex-row space-x-2 space-y-2 h-16 md:h-12 items-center transition-all duration-500">
                {Boolean(audioURL && !recording) && <Waveform audio={audioURL} />}
                <div onClick={recording ? stopRecording : startRecording} className={`${recording ? 'justfiy-between px-4 space-x-2' : 'justify-center'} z-50 flex w-full rounded-full bg-gray-200 h-12 flex-row items-center group hover:cursor-pointer hover:shadow-inner transition-all`}>
                    {recording && <div className="h-2 w-2 max-h-2 max-w-2 rounded-full bg-red-500 " />}

                    <div className={`${Boolean(!audioURL || recording) ? 'opacity-100' : 'opacity-0'}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 opacity-50 group-hover:opacity-80 ">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z" />
                        </svg>
                    </div>

                    {Boolean(audioURL && !recording) && <div className=" text-gray-500/50 pr-2">Re-take</div>}
                    {Boolean(audioURL && !recording) && <div onClick={submitVoice} className="text-green-500 rounded-md px-2 font-semibold ">Confirm</div>}
                    {recording && (
                        <div className={`text-gray-400 font-mono w-full flex flex-row justify-end`}>
                            {elapsedTime < 10 ? `0:0${elapsedTime}` : `0:${elapsedTime}`} / 0:10
                        </div>
                    )}
                </div>


                {/* Seconds Indicator */}


            </div>
            <div className={`z-10 w-full ${recording ? 'h-36 md:h-28 shadow-inner bg-gray-100 px-2 pb-1 pt-1 md:pt-2 md:pb-2 md:py-2 md:mt-4' : 'h-0'} transition-all duration-500 overflow-hidden rounded-lg `}>
                <div className="text-md font-semibold text-gray-500 w-full text-center">
                    Read the below text
                </div>
                <div className="text-sm text-gray-400 pt-1">The quick brown fox jumps over a lazy dog as the bright sun sets behind the tall mountains, while cheerful birds sing and five clever wizards chant mystical words under the shimmering moonlight
                </div>
            </div>
        </>

    );
};

export default AudioRecorder;
