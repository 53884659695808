// SortableItem.jsx
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

const SortableItem = ({ id, text }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: 'none',
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners} className="p-4 m-1 bg-gray-200 rounded shadow cursor-grab">
            {text}
        </div>
    );
};

export default SortableItem;
