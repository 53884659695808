import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './AuthContextProvider'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthContextProvider>
    <div className="h-[100%] absolute inset-0 bg-gradient-to-b from-cyan-300/40 via-pink-500/20 to-sky-500/45" />
    <div className=" absolute inset-0 text-slate-900/[0.1] [mask-image:linear-gradient(to_bottom_left,white,white,transparent)]"><svg className="absolute inset-0 h-full w-full" xmlns="http://www.w3.org/2000/svg"><defs><pattern id="grid-bg" width="32" height="32" patternUnits="userSpaceOnUse" x="100%" patternTransform="translate(0 -1)"><path d="M0 32V.5H32" fill="none" stroke="currentColor"></path></pattern></defs><rect width="100%" height="100%" fill="url(#grid-bg)"></rect></svg></div>
    <div className="w-full h-dvh">
      <App />
    </div>
  </AuthContextProvider>
);

reportWebVitals();
