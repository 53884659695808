'use client';
import React, { useEffect, useState, createRef } from "react";
import { useAuthContext } from "../AuthContextProvider";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import { useLocation } from "wouter";
import TwitterLogo from '../assets/images/twitter_logo.png'

const providerGoogle = new GoogleAuthProvider();
const providerTwitter = new TwitterAuthProvider();

export default function Auth() {
    const [location, setLocation] = useLocation();

    const auth = getAuth();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { user, loading } = useAuthContext();

    useEffect(() => {
        if (user !== null && !loading) {
            // Redirect after 3 seconds
            setLocation("/discover")
        }
    });

    const login = () => {
        localStorage.clear();

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                console.log('user', user)
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('error', error);

                // If user doesn't exist, create user
                if (errorCode === "auth/user-not-found") {
                    createUserWithEmailAndPassword(auth, email, password)
                        .then((userCredential) => {
                            // Signed up 
                            const user = userCredential.user;
                            // ...
                            console.log('user', user)
                        })
                        .catch((error) => {
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            console.log('error', error);
                            // ..
                        });
                }
            });
    }

    const signInWithSocials = (provider) => {
        localStorage.clear();

        signInWithPopup(auth, provider)
            .then((result) => {
                // Signed in 
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                const user = result.user;
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }



    return (
        <div className="h-full">

            <div className="relative transition-all flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">

                <div className=" w-full sm:mx-auto sm:w-full sm:max-w-[480px] px-12">
                    <div className=" overflow-none relative">
                        <div className="z-1 absolute w-full h-full bg-gray-200/35 blur-sm rounded-xl" />

                        <div className="bg-white/70  rounded-lg relative flex flex-col justify-start px-3 py-3 space-y-2 border border-gray-200">

                            <div className="relative z-2 w-full flex flex-row justify-between items-center">
                                <div className="flex flex-row space-x-2 items-center text-gray-600 font-regular text-xs"><div className="text-lg rounded-lg border border-gray-200 bg-gray-100 h-8 w-8 flex flex-row justify-center items-center">👀</div><div className="">KooKoo Auth</div></div>
                                <div className="text-regular text-gray-600/80 text-xs">now</div>
                            </div>
                            <div className="relative w-full flex flex-col">
                                <div className="text-regular text-gray-600 text-sm">Hi! 👋</div>
                                <div className="text-regular text-gray-500 text-xs">Please login or create an account to continue ^^</div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px] px-12 ">
                    <div className="border border-gray-300 rounded-xl">
                        <div className="bg-white px-6 py-6 shadow-xl rounded-xl sm:px-12  border-8 border-gray-100">
                            <form id="sign-in-button" className="space-y-6" action="#" method="POST">
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                        Email address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            required
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="block w-full rounded-md border border-gray-200 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                        Password
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            autoComplete="current-password"
                                            required
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="block w-full rounded-md  py-1.5 px-2 text-gray-900 shadow-sm border border-gray-200 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <input
                                            id="remember-me"
                                            name="remember-me"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                        />
                                        <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                                            Remember me
                                        </label>
                                    </div>

                                    <div className="text-sm leading-6">
                                        <a href="#" className="font-semibold text-sky-600 hover:text-sky-500">
                                            Forgot password?
                                        </a>
                                    </div>
                                </div>

                                <div>
                                    <button
                                        onClick={(e) => { e.preventDefault(); login() }}
                                        type="submit"
                                        className="flex w-full justify-center rounded-md bg-sky-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Sign in
                                    </button>
                                </div>
                            </form>

                            <div>
                                <div className="relative mt-4">
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="w-full border-t border-gray-200" />
                                    </div>
                                    <div className="relative flex justify-center text-sm font-medium leading-6">
                                        <span className="bg-white px-6 text-gray-900">Or continue with</span>
                                    </div>
                                </div>

                                <div className="mt-2 grid grid-cols-2 gap-4">
                                    <div
                                        onClick={() => { signInWithSocials(providerGoogle) }}
                                        className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
                                    >
                                        <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                                            <path
                                                d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                                                fill="#EA4335"
                                            />
                                            <path
                                                d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                                                fill="#4285F4"
                                            />
                                            <path
                                                d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                                                fill="#FBBC05"
                                            />
                                            <path
                                                d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                                                fill="#34A853"
                                            />
                                        </svg>
                                        <span className="text-sm font-semibold leading-6">Google</span>
                                    </div>

                                    <div
                                        onClick={() => { signInWithSocials(providerTwitter) }}
                                        className="flex w-full items-center justify-center gap-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
                                    >

                                        <img className="h-5 w-5 rounded-md" src={TwitterLogo} alt="Twitter" />
                                        <span className="text-sm font-semibold leading-6">X (Twitter)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    )
}
