import { useEffect, useState } from 'react';
import './App.css';
import { Route } from "wouter";
import Auth from './pages/Auth.js'
import Landing from './pages/Landing.js';
import Onboarding from './pages/Onboarding.js'
import Results from './pages/Results.js'
import Results2 from './pages/Results2.js'
import Results3 from './pages/Results3.js'
import { useAuthContext } from "./AuthContextProvider";
import { useLocation } from "wouter";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

function App() {
  const { user, loading } = useAuthContext()
  const [location, setLocation] = useLocation();
  console.log('location', location);


  /*
  useEffect(() => {
    if (location !== '/') {
      if (user == null && !loading) {
        // Redirect after 3 seconds
        setTimeout(() => {
          setLocation("/auth")
        }, 3000)
      }
    }
  }, [user, loading])
  */

  const logout = () => {
    const auth = getAuth();
    auth.signOut();
  }



  return (
    <div className="relative flex flex-col w-full h-full">

      <Route path="/"><Landing /></Route>


      <Route path="/auth" component={Auth} />


      <Route path="/onboarding">
        <Onboarding />
      </Route>

      <Route path="/results">
        <Results3 />
      </Route>

    </div>
  );
}

export default App;
